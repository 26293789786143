<template>
  <div class="mb-10">
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <bullet
        :canBack="true"
        :colorIcon="'#052633'"
        :title="'Update Invoice'"
      />
      <div class="parent">
        <div v-if="!loading && dataSet" class="mt-4">
          <h4 class="not_bold">Nama Pelanggan</h4>
          <v-select
            placeholder="Silakan pilih nama pelanggan di sini"
            outlined
            hide-details
            :items="dataPelanggan"
            item-text="nama_lengkap"
            item-value="id"
            v-model="dataSet.klien_costumer_id"
            dense
            class="rounded-lg"
            filled
            readonly
          ></v-select>
        </div>
        <div v-if="loading" class="mt-4">
          <v-skeleton-loader
            class="rounded-lg"
            width="90px"
            height="20px"
            type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="rounded-lg mt-1"
            width="100%"
            height="40px"
            type="image"
          ></v-skeleton-loader>
        </div>
        <div class="mt-4 d-flex align-center">
          <v-row no-gutters justify="space-between">
            <v-col cols="12" md="4" class="pr-1">
              <div v-if="!loading && dataSet" class="mt-2">
                <h4 class="not_bold">Tanggal Transaksi</h4>
                <v-menu
                  v-model="menuTransaksi"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dataSet.tgl_transaksi"
                      prepend-inner-icon="mdi-calendar-range"
                      readonly
                      placeholder="Tanggal transaksi"
                      hide-details
                      dense
                      v-on="on"
                      outlined
                      class="rounded-lg"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dataSet.tgl_transaksi"
                    @input="
                      closeDateMenu(dataSet.tgl_transaksi, 'tgl_transaksi')
                    "
                  ></v-date-picker>
                </v-menu>
              </div>
              <div v-if="loading" class="mt-2">
                <v-skeleton-loader
                  class="rounded-lg"
                  width="90px"
                  height="20px"
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="rounded-lg mt-1"
                  width="100%"
                  height="40px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </v-col>
            <v-col cols="12" md="4" class="px-1">
              <div v-if="!loading && dataSet" class="mt-2">
                <h4 class="not_bold">Tanggal Jatuh Tempo</h4>
                <v-menu
                  v-model="menuJatuh"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dataSet.due_date"
                      prepend-inner-icon="mdi-calendar-range"
                      readonly
                      placeholder="Tanggal Jatuh Tempo"
                      hide-details
                      dense
                      v-on="on"
                      outlined
                      class="rounded-lg"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dataSet.tgl_jatuh"
                    @input="closeDateMenu(dataSet.due_date, 'tgl_jatuh')"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div v-if="loading" class="mt-2">
                <v-skeleton-loader
                  class="rounded-lg"
                  width="90px"
                  height="20px"
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="rounded-lg mt-1"
                  width="100%"
                  height="40px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </v-col>
            <v-col cols="12" md="4" class="pl-1">
              <div v-if="!loading && dataSet" class="mt-2">
                <h4 class="not_bold">ID Transaksi</h4>
                <v-text-field
                  readonly
                  filled
                  v-model="dataSet.id"
                  placeholder="Isi id transaksi disini"
                  hide-details
                  dense
                  outlined
                  class="rounded-lg"
                ></v-text-field>
              </div>
              <div v-if="loading" class="mt-2">
                <v-skeleton-loader
                  class="rounded-lg"
                  width="90px"
                  height="20px"
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="rounded-lg mt-1"
                  width="100%"
                  height="40px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div v-if="!loading && dataSet">
      <v-simple-table
        class="mt-4 pb-2 rounded"
        style="overflow: hidden; border: 1px solid grey"
      >
        <template v-slot:default>
          <thead style="background: #052633">
            <tr>
              <th class="text-left" style="color: white">Layanan</th>
              <th class="text-left" style="color: white">Kuantitas</th>
              <th class="text-left" style="color: white">Harga Satuan</th>
              <th class="text-left" style="color: white">Diskon</th>
              <th class="text-left" style="color: white">Pajak</th>
              <th class="text-left" style="color: white">Jumlah</th>
              <th class="text-left" style="color: white"></th>
            </tr>
          </thead>
          <tbody style="background: white">
            <tr v-for="(item, n) in itemsInvoice" :key="n">
              <td>
                <v-select
                  style="min-width: 80px"
                  class="my-2 rounded-lg"
                  v-if="dataSet"
                  placeholder="Silakan isi nama di sini"
                  outlined
                  hide-details
                  :items="optLayanan"
                  item-text="layanan"
                  item-value="id"
                  v-model="item.layanan_id"
                  dense
                  @change="addItem(n)"
                ></v-select>
              </td>
              <td>
                <v-text-field
                  style="width: 80px"
                  class="my-2 rounded-lg"
                  @blur="countSum(n)"
                  v-model="item.qty"
                  placeholder="Tulis kuantitas"
                  hide-details
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </td>
              <td>
                <div
                  class="rounded-lg my-2 d-flex align-center"
                  style="height: 70%; border: 1px solid grey; overflow: hidden"
                >
                  <v-card
                    rounded="0"
                    class="px-2 d-flex align-center justify-center"
                    flat
                    color="#052633"
                    style="width: 30px; height: 100%"
                  >
                    <v-icon small color="white">Rp.</v-icon>
                  </v-card>
                  <currency-input
                    @change="countSum(n)"
                    class_temp="rounded-0"
                    class="pa-2"
                    style="height: 100%"
                    v-model="item.cost"
                    :placeholder="'Tulis harga satuan'"
                    :options="{
                      currency: 'IDR',
                      currencyDisplay: 'hidden',
                      hideCurrencySymbolOnFocus: false,
                      hideGroupingSeparatorOnFocus: true,
                      hideNegligibleDecimalDigitsOnFocus: true,
                      autoDecimalDigits: true,
                      autoSign: true,
                      useGrouping: true,
                      accountingSign: false,
                      precision: 0,
                      locale: 'in',
                      valueRange: { min: 0, max: 999999999999 },
                    }"
                  />
                </div>
              </td>
              <td>
                <v-text-field
                  style="width: 80px"
                  class="my-2 rounded-lg"
                  @blur="countSum(n)"
                  outlined
                  v-model="item.diskon"
                  placeholder="Tulis diskon"
                  hide-details
                  dense
                  type="number"
                ></v-text-field>
              </td>
              <td>
                <v-select
                  style="max-width: 120px"
                  class="my-2 rounded-lg"
                  placeholder="Pajak"
                  outlined
                  hide-details
                  :items="optPajak"
                  item-text="text"
                  item-value="value"
                  v-model="item.pajak"
                  dense
                  @change="countSum(n)"
                ></v-select>
              </td>
              <td>
                <div
                  class="my-2 rounded-lg d-flex align-center"
                  style="height: 70%; border: 1px solid grey; overflow: hidden"
                >
                  <v-card
                    rounded="0"
                    class="px-2 d-flex align-center justify-center"
                    flat
                    color="#052633"
                    style="width: 30px; height: 100%"
                  >
                    <v-icon small color="white">Rp.</v-icon>
                  </v-card>
                  <currency-input
                    :disabled="true"
                    @change="countSum(n)"
                    class_temp="rounded-0"
                    class="pa-2"
                    style="height: 100%"
                    :placeholder="'Tulis jumlah'"
                    v-model="item.jumlah"
                    :options="{
                      currency: 'IDR',
                      currencyDisplay: 'hidden',
                      hideCurrencySymbolOnFocus: false,
                      hideGroupingSeparatorOnFocus: true,
                      hideNegligibleDecimalDigitsOnFocus: true,
                      autoDecimalDigits: true,
                      autoSign: true,
                      useGrouping: true,
                      accountingSign: false,
                      precision: 0,
                      locale: 'in',
                      valueRange: { min: 0, max: 999999999999 },
                    }"
                  />
                </div>
              </td>
              <td>
                <v-btn
                  v-if="n != itemsInvoice.length - 1"
                  @click="deleteData(item)"
                  x-small
                  dark
                  depressed
                  color="#052633"
                >
                  <v-icon small>mdi-minus</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div v-if="loading">
      <v-skeleton-loader
        class="pa-2 elevation-0"
        type="table-tbody"
      ></v-skeleton-loader>
    </div>
    <div class="mt-4 px-3">
      <v-row no-gutters>
        <v-col cols="12" md="6">
          <div v-if="!loading && dataSet" class="pa-2">
            <h4 class="not_bold">Notes</h4>
            <v-textarea
              rows="4"
              auto-grow
              v-model="dataSet.note"
              placeholder="Isi note disini"
              hide-details
              dense
              outlined
              class="rounded-lg"
            ></v-textarea>
          </div>
          <div v-if="loading" class="pa-2">
            <v-skeleton-loader
              class="rounded-lg"
              width="90px"
              height="20px"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="rounded-lg mt-1"
              width="100%"
              height="90px"
              type="image"
            ></v-skeleton-loader>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="pa-2" v-if="!loading && dataSet">
            <div class="d-flex justify-space-between">
              <h4>Subtotal</h4>
              <h4>Rp. {{ formatRupiah(field.total) }}</h4>
            </div>
            <div class="d-flex justify-space-between">
              <h4>Diskon per baris</h4>
              <h4>
                Rp.
                {{ formatRupiah(field.total_diskon) }}
              </h4>
            </div>
            <div class="d-flex align-center justify-space-between">
              <div class="d-flex align-center" style="height: 100%">
                <h4>Diskon</h4>
                <div
                  class="mx-1 pa-0 d-flex align-center rounded-lg"
                  style="border: 1px solid grey; overflow: hidden"
                >
                  <v-btn
                    class="rounded-0"
                    depressed
                    :dark="dataSet.persen_diskon == 1 ? true : false"
                    @click="changePersen('diskon')"
                    :style="
                      dataSet.persen_diskon == 1
                        ? 'background:#052633;'
                        : 'background:white'
                    "
                  >
                    <v-icon small>%</v-icon>
                  </v-btn>
                  <v-btn
                    class="text-capitalize rounded-0"
                    depressed
                    :style="
                      !dataSet.persen_diskon == 1
                        ? 'background:#052633'
                        : 'background:white'
                    "
                    :dark="dataSet.persen_diskon == 1 ? false : true"
                    @click="changePersen('diskon')"
                  >
                    <v-icon small>Rp.</v-icon>
                  </v-btn>
                </div>
                <v-text-field
                  v-if="dataSet.persen_diskon"
                  style="width: 60px; background: white"
                  outlined
                  class="rounded-lg"
                  hide-details
                  dense
                  type="number"
                  v-model="dataSet.diskon"
                  @blur="countSum(-2)"
                  @change="countSum(-2)"
                ></v-text-field>
                <currency-input
                  @change="countSum(-2)"
                  v-if="!dataSet.persen_diskon"
                  class_temp="rounded-lg"
                  class="rounded-lg pa-2"
                  style="height: 4vh; border: 1px solid grey"
                  v-model="dataSet.diskon"
                  :options="{
                    currency: 'IDR',
                    currencyDisplay: 'hidden',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: true,
                    hideNegligibleDecimalDigitsOnFocus: true,
                    autoDecimalDigits: true,
                    autoSign: true,
                    useGrouping: true,
                    accountingSign: false,
                    precision: 0,
                    locale: 'in',
                    valueRange: { min: 0, max: 999999999999 },
                  }"
                />
              </div>
              <h4>Rp. {{ formatRupiah(field.diskon_tamhahan_txt) }}</h4>
            </div>
            <div class="d-flex justify-space-between">
              <h4>PPN</h4>
              <h4>Rp. {{ formatRupiah(field.sum_pajak) }}</h4>
            </div>
            <v-divider class="my-4"></v-divider>
            <!-- <div class="d-flex justify-space-between">
              <h4>Total</h4>
              <h4>Rp. {{ field.total_before_cut }}</h4>
            </div>
            <div class="d-flex justify-space-between flex-column">
              <v-checkbox
                color="#052633"
                class="ma-0"
                v-model="dataSet.is_cut"
                label="Pemotongan"
                @change="countSum(-2)"
              ></v-checkbox>
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex">
                  <div
                    class="mx-1 pa-0 d-flex rounded"
                    style="border: 1px solid grey; overflow: hidden"
                  >
                    <v-btn
                      height="100%"
                      class="rounded-0"
                      depressed
                      :dark="
                        dataSet.persen_potongan == 1 ? true : false
                      "
                      @click="changePersen('potongan')"
                      :style="
                        dataSet.persen_potongan == 1
                          ? 'background:#052633;'
                          : 'background:white'
                      "
                    >
                      <v-icon small>%</v-icon>
                    </v-btn>
                    <v-btn
                      height="100%"
                      class="text-capitalize rounded-0"
                      depressed
                      :style="
                        !dataSet.persen_potongan == 1
                          ? 'background:#052633'
                          : 'background:white'
                      "
                      :dark="
                        dataSet.persen_potongan == 1 ? false : true
                      "
                      @click="changePersen('potongan')"
                    >
                      <v-icon small>Rp.</v-icon>
                    </v-btn>
                  </div>
                  <v-text-field
                    style="width: 60px; background: white"
                    outlined
                    class="rounded"
                    hide-details
                    dense
                    type="number"
                    v-model="dataSet.potongan"
                    @blur="countSum(-2)"
                    @change="countSum(-2)"
                  ></v-text-field>
                </div>
                <h4>
                  Rp.
                  {{
                    dataSet.is_cut == 1 ? field.potongan_txt : 0
                  }}
                </h4>
              </div>
            </div>
            <v-divider class="my-4"></v-divider> -->
            <div class="mt-2 d-flex justify-space-between">
              <h4>Total Tagihan</h4>
              <h4>Rp. {{ formatRupiah(dataSet.total_tagihan) }}</h4>
            </div>
            <v-divider class="my-4"></v-divider>
          </div>

          <div v-if="loading">
            <v-skeleton-loader
              type="list-item-three-line, article"
            ></v-skeleton-loader>
          </div>
        </v-col>
        <v-col cols="12" md="12">
          <v-row no-gutters justify="end">
            <v-col cols="12" md="2">
              <div v-if="!loading && dataSet" class="pa-2 d-flex justify-end">
                <v-btn
                  @click="updateData"
                  block
                  dark
                  color="#052633"
                  class="text-capitalize mt-1"
                >
                  <h4 v-if="!loadingUpdate">Update</h4>
                  <h4 v-if="loadingUpdate">Loading..</h4>
                </v-btn>
              </div>
              <div v-if="loading" class="pa-2 d-flex justify-end">
                <v-skeleton-loader
                  class="rounded-lg mt-1"
                  width="100%"
                  height="40px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="pa-2">
      <dialog-small
        :d_small="d_small"
        @refetch="fetchData('delete')"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CurrencyInput from "../../components/currencyInput/CurrencyInput.vue";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { bullet, NavbarProfile, CurrencyInput },
  name: "updateInvoice",
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      loadingUpdate: false,
      loading: true,
      dataSet: null,
      dataPelanggan: null,
      menuTransaksi: false,
      menuJatuh: false,
      persen_diskon: true,
      persen_potongan: false,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      itemsInvoice: [
        {
          id: null,
          layanan: "",
          layanan_id: null,
          jenis: "",
          qty: 0,
          cost: 0,
          diskon: 0,
          pajak: 0,
          jumlah: 0,
        },
      ],
      optLayanan: [],
      optPajak: [
        { text: "PPN", value: 11 },
        { text: "Kosong", value: 0 },
      ],
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Invoice",
          disabled: false,
          href: "/admin/mku/invoice",
        },
        {
          text: "Update Invoice",
          disabled: true,
          href: "#",
        },
      ],
      field: {
        total: 0,
        total_diskon: 0,
        pilihan_potongan: "",
        diskon_tambahan: 0,
        diskon_tamhahan_txt: 0,
        sum_pajak: 0,
        total_before_cut: 0,
        is_cut: false,
        potongan: 0,
        potongan_txt: 0,
        total_tagihan: 0,
      },
    };
  },
  mounted() {
    this.fetchDataLayanan();
  },
  methods: {
    formatRupiah(angka) {
      var temp_angka = angka.toString().replace(".", ",");
      var number_string = temp_angka,
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

      if (ribuan) {
        let separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return rupiah;
    },
    fetchDataLayanan() {
      this.loading = true;
      let data = {
        path: `sysadmin/event/daftar-layanan`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.optLayanan = data;
          this.fetchDataCustomer();
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    fetchDataCustomer() {
      this.loading = true;
      let data = {
        path: `sysadmin/customer/list`,
      };
      this.$store
        .dispatch("officer/getData", data)
        .then((data) => {
          this.dataPelanggan = data.data;
          this.fetchData("");
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    fetchData(value) {
      this.loading = true;
      let data = {
        path: `sysadmin/admin/invoice/get?filter[id]=${this.$route.params.idInvoice}`,
      };
      this.$store
        .dispatch("officer/getData", data)
        .then((data) => {
          console.log(data.data[0]);
          this.dataSet = data.data[0];
          this.dataSet.is_cut = 0;
          this.dataSet.potongan = 0;
          this.dataSet.persen_potongan = 0;
          if (this.dataSet.item.length > 0) {
            this.itemsInvoice = [];
            this.dataSet.item.forEach((item, index) => {
              this.itemsInvoice.push({
                id: item.id,
                layanan: item.name,
                layanan_id: item.layanan_id,
                jenis: item.jenis,
                qty: item.qty,
                cost: item.cost,
                diskon: item.diskon,
                pajak: item.pajak,
                jumlah: 0,
              });
              this.countSum(index);
            });
          }
          this.addItem(this.dataSet.item.length - 1);
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
      if (value) {
      }
    },
    closeDateMenu(item, type) {
      if (type == "tgl_transaksi") {
        this.menuTransaksi = false;
        this.dataSet.tgl_transaksi == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      } else {
        this.menuJatuh = false;
        this.dataSet.due_date == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      }
    },
    addItem(idx) {
      for (let i = 0; i < this.optLayanan.length; i++) {
        if (this.itemsInvoice[idx].layanan_id == this.optLayanan[i].id) {
          this.itemsInvoice[idx].layanan = this.optLayanan[i].layanan;
        }
      }
      if (idx == this.itemsInvoice.length - 1)
        this.itemsInvoice.push({
          id: null,
          layanan_id: null,
          layanan: "",
          jenis: "",
          qty: 0,
          cost: 0,
          diskon: 0,
          pajak: 0,
          jumlah: 0,
        });
    },
    removeArray(idx) {
      this.itemsInvoice.splice(idx, 1);
      this.countSum(-2);
    },
    countSum(n) {
      let sumHarga = 0;
      let sumDiskon = 0;
      let sumPpn = 0;
      if (n != -2) {
        this.itemsInvoice[n].jumlah =
          this.itemsInvoice[n].qty * this.itemsInvoice[n].cost;
      }
      this.itemsInvoice.forEach((item) => {
        if (item.layanan) {
          sumHarga =
            parseInt(item.qty) * parseInt(item.cost) + parseInt(sumHarga);
          sumDiskon =
            (parseInt(item.diskon) *
              (parseInt(item.qty) * parseInt(item.cost))) /
              100 +
            parseInt(sumDiskon);
          sumPpn = parseInt(item.pajak) + parseInt(sumPpn);
        }
      });

      this.field.total = sumHarga;
      this.field.total_diskon = sumDiskon;
      if (this.dataSet.persen_diskon == 1) {
        this.field.diskon_tamhahan_txt =
          ((this.field.total - this.field.total_diskon) * this.dataSet.diskon) /
          100;
      } else {
        this.field.diskon_tamhahan_txt = this.dataSet.diskon;
      }
      this.field.sum_pajak =
        ((this.field.total -
          this.field.total_diskon -
          this.field.diskon_tamhahan_txt) *
          sumPpn) /
        100;
      this.field.total_before_cut =
        this.field.total -
        this.field.total_diskon -
        this.field.sum_pajak -
        this.field.diskon_tamhahan_txt;
      if (this.dataSet.persen_potongan == 1) {
        this.field.potongan_txt =
          (this.field.total_before_cut * this.dataSet.potongan) / 100;
      } else {
        this.field.potongan_txt = this.dataSet.potongan;
      }
      if (this.dataSet.is_cut == 1) {
        this.dataSet.total_tagihan =
          this.field.total_before_cut - this.field.potongan_txt;
      } else {
        this.dataSet.total_tagihan = this.field.total_before_cut;
      }
    },
    changePersen(type) {
      if (type == "diskon") {
        if (this.dataSet.persen_diskon == 1) {
          this.dataSet.persen_diskon = 0;
        } else {
          this.dataSet.persen_diskon = 1;
        }
        this.countSum(-2);
      } else {
        if (this.dataSet.persen_potongan == 1) {
          this.dataSet.persen_potongan = 0;
        } else {
          this.dataSet.persen_potongan = 1;
        }
        this.countSum(-2);
      }
    },

    updateData() {
      this.loadingUpdate = true;
      let itemsInvoice = [];
      this.itemsInvoice.forEach((item) => {
        if (item.layanan_id) {
          itemsInvoice.push({
            id: item.id,
            name: item.layanan,
            layanan_id: item.layanan_id,
            jenis: item.jenis,
            qty: item.qty,
            cost: item.cost,
            diskon: item.diskon,
            pajak: item.pajak,
            // jumlah: item.jumlah,
            // total: this.field.total,
            // total_diskon: this.field.total_diskon,
            // diskon_tambahan: this.field.diskon_tambahan,
            // diskon_tamhahan_txt: this.field.diskon_tamhahan_txt,
            // sum_pajak: this.field.sum_pajak,
            // total_before_cut: this.field.total_before_cut,
            // potongan: this.field.potongan,
            // potongan_txt: this.field.potongan_txt,
          });
        }
      });

      let data = {
        body: {
          id: this.$route.params.idInvoice,
          klien_costumer_id: this.dataSet.klien_costumer_id,
          due_date: this.dataSet.due_date,
          status: this.dataSet.status,
          note: this.dataSet.note,
          array_json: itemsInvoice,
          payment_method_id: 1,
          tgl_transaksi: this.dataSet.tgl_transaksi,
          persen_diskon: this.dataSet.persen_diskon,
          diskon: this.dataSet.diskon,
          persen_potongan: this.dataSet.persen_potongan,
          potongan: this.dataSet.potongan,
          is_cut: this.dataSet.is_cut,
          total_tagihan: this.dataSet.total_tagihan,
        },
        path: `sysadmin/admin/invoice/update`,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.loadingUpdate = false;
          if (data.status != "success") {
            if (data.data) {
              var key = Object.keys(data.data.errors);
              var values = Object.values(data.data.errors);
              for (let i = 0; i < key.length; i++) {
                for (let j = 0; j < values[i].length; j++) {
                  this.$toast.error(values[i][j]);
                }
              }
            } else {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
          } else {
            this.loadingUpdate = false;
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
            this.fetchData("");
          }
        })
        .catch((e) => {
          this.loadingUpdate = false;
          if (e.data) {
            var key = Object.keys(e.data.errors);
            var values = Object.values(e.data.errors);
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          } else {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
        });
    },
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/admin/invoice/delete/timeline`,
        deleteType: "Timeline",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Timeline";
      this.d_small = true;
      this.line = true;
    },
  },
};
</script>

<style scoped>
.v-data-table tbody tr:nth-child(even) {
  background-color: white !important;
}
.parent {
  width: 60%;
}
@media (max-width: 960px) {
  .parent {
    width: 100%;
  }
}
</style>
